var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pb-10", attrs: { elevation: "0" } },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-0 mb-6" },
        [
          _c(
            "v-row",
            {
              staticClass: "mx-auto mt-2",
              class: _vm.$vuetify.breakpoint.smAndDown ? "mb-8" : "mb-6",
              attrs: { justify: "center" },
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "text-h5 font-weight-bold grey--text text--darken-4 mx-auto text-center",
                  staticStyle: { display: "block" },
                },
                [
                  _vm.$vuetify.breakpoint.mdAndUp
                    ? [_vm._v(" Company and Billing Information ")]
                    : [
                        _vm._v(" Company and Billing"),
                        _c("br"),
                        _vm._v("Information "),
                      ],
                ],
                2
              ),
            ]
          ),
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c("v-divider", { staticClass: "mx-8" })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-card-text",
        {
          staticClass: "pa-0",
          class: _vm.$vuetify.breakpoint.mdAndUp ? "px-6" : "pl-1 pr-0",
        },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", justify: "center" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "pl-3 pr-6 company-info-form-container",
                  attrs: { cols: "12", lg: "8", md: "8" },
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "mb-4 subtitle-1 font-weight-medium grey--text text--darken-4",
                      attrs: { "no-gutters": "", justify: "center" },
                    },
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("811CenterAndExcavatorType")) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mb-2" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0",
                          attrs: { cols: "12", lg: "6", md: "6" },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.select811CenterOptions,
                              "error-messages": _vm.centerErrors,
                              outlined: "",
                              "menu-props": { bottom: true, offsetY: true },
                              required: "",
                              dense: "",
                              label: _vm.$t("811Center"),
                              "item-text": "center_name",
                              "item-value": "center_id",
                            },
                            on: {
                              change: _vm.onCenterChange,
                              blur: _vm.onCenterChange,
                            },
                            model: {
                              value: _vm.selectedCenter,
                              callback: function ($$v) {
                                _vm.selectedCenter = $$v
                              },
                              expression: "selectedCenter",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0",
                          attrs: { cols: "12", lg: "6", md: "6" },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.excavatorTypes,
                              disabled: _vm.disableExcavatorTypeSelection,
                              "error-messages": _vm.centerTypeErrors,
                              outlined: "",
                              dense: "",
                              "menu-props": { bottom: true, offsetY: true },
                              required: "",
                              label: _vm.$t("excavatorType"),
                              "item-value": "text",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$v.excavatorType.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.excavatorType.$touch()
                              },
                            },
                            model: {
                              value: _vm.excavatorType,
                              callback: function ($$v) {
                                _vm.excavatorType = $$v
                              },
                              expression: "excavatorType",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-row",
                    { staticClass: "mt-8 mb-6", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "subtitle-1 font-weight-medium grey--text text--darken-4",
                          attrs: { cols: "12" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("companyInformation")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "error-messages": _vm.companyNameErrors,
                              label: _vm.$t("companyName"),
                              outlined: "",
                              required: "",
                              dense: "",
                              disabled: _vm.disableCompanyInfoForm,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.companyName.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.companyName.$touch()
                              },
                            },
                            model: {
                              value: _vm.companyName,
                              callback: function ($$v) {
                                _vm.companyName = $$v
                              },
                              expression: "companyName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0",
                          attrs: { cols: "12", lg: "6", md: "6" },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "error-messages": _vm.companyPhoneErrors,
                              label: _vm.$t("companyPhone"),
                              disabled: _vm.disableCompanyInfoForm,
                              outlined: "",
                              required: "",
                              dense: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.companyPhone.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.companyPhone.$touch()
                              },
                            },
                            model: {
                              value: _vm.companyPhone,
                              callback: function ($$v) {
                                _vm.companyPhone = $$v
                              },
                              expression: "companyPhone",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0",
                          attrs: { cols: "12", lg: "6", md: "6" },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "error-messages": _vm.companyEmailErrors,
                              label: _vm.$t("companyEmail"),
                              disabled: _vm.disableCompanyInfoForm,
                              outlined: "",
                              required: "",
                              dense: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.companyEmail.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.companyEmail.$touch()
                              },
                            },
                            model: {
                              value: _vm.companyEmail,
                              callback: function ($$v) {
                                _vm.companyEmail = $$v
                              },
                              expression: "companyEmail",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0",
                          attrs: { cols: "12", lg: "6", md: "6" },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "error-messages": _vm.companyAddressErrors,
                              label: _vm.$t("companyAddressText"),
                              disabled: _vm.disableCompanyInfoForm,
                              outlined: "",
                              required: "",
                              dense: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.companyAddress.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.companyAddress.$touch()
                              },
                            },
                            model: {
                              value: _vm.companyAddress,
                              callback: function ($$v) {
                                _vm.companyAddress = $$v
                              },
                              expression: "companyAddress",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0",
                          attrs: { cols: "12", lg: "6", md: "6" },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "error-messages": _vm.companyCityErrors,
                              label: _vm.$t("cityText"),
                              disabled: _vm.disableCompanyInfoForm,
                              outlined: "",
                              required: "",
                              dense: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.companyCity.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.companyCity.$touch()
                              },
                            },
                            model: {
                              value: _vm.companyCity,
                              callback: function ($$v) {
                                _vm.companyCity = $$v
                              },
                              expression: "companyCity",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mb-2", attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0",
                          attrs: { cols: "12", lg: "6", md: "6" },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.selectState,
                              "error-messages": _vm.companyStateErrors,
                              disabled: _vm.disableCompanyInfoForm,
                              outlined: "",
                              "menu-props": { bottom: true, offsetY: true },
                              required: "",
                              dense: "",
                              label: _vm.$t("stateText"),
                              "item-value": "text",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$v.companyState.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.companyState.$touch()
                              },
                            },
                            model: {
                              value: _vm.companyState,
                              callback: function ($$v) {
                                _vm.companyState = $$v
                              },
                              expression: "companyState",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0",
                          attrs: { cols: "12", lg: "6", md: "6" },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "error-messages": _vm.companyZipErrors,
                              label: _vm.$t("zipText"),
                              disabled: _vm.disableCompanyInfoForm,
                              outlined: "",
                              required: "",
                              dense: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.companyZip.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.companyZip.$touch()
                              },
                            },
                            model: {
                              value: _vm.companyZip,
                              callback: function ($$v) {
                                _vm.companyZip = $$v
                              },
                              expression: "companyZip",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.selectedPlan !== "Free" ? _c("v-divider") : _vm._e(),
                  _vm.selectedPlan !== "Free"
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "mt-6",
                              attrs: { "no-gutters": "", justify: "center" },
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "subtitle-1 font-weight-medium grey--text text--darken-4",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("paymentMethod")) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "mt-4 radio-group-row",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-radio-group",
                                    {
                                      attrs: { row: "", "hide-details": "" },
                                      model: {
                                        value: _vm.paymentType,
                                        callback: function ($$v) {
                                          _vm.paymentType = $$v
                                        },
                                        expression: "paymentType",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass:
                                            "pa-3 payment-type-option",
                                          class:
                                            _vm.paymentType === "invoice"
                                              ? "rounded-lg billing-type-style-primary"
                                              : "rounded-lg billing-type-style",
                                          attrs: {
                                            outlined: "",
                                            disabled:
                                              _vm.disableCompanyInfoForm,
                                            width: _vm.$vuetify.breakpoint
                                              .mdAndUp
                                              ? "175"
                                              : "50%",
                                            height: "50",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: _vm.$t("invoice"),
                                              value: "invoice",
                                              disabled:
                                                _vm.disableCompanyInfoForm,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card",
                                        {
                                          staticClass:
                                            "pa-3 ml-5 payment-type-option",
                                          class:
                                            _vm.paymentType === "creditCard"
                                              ? "rounded-lg billing-type-style-primary"
                                              : "rounded-lg billing-type-style",
                                          attrs: {
                                            outlined: "",
                                            light: "",
                                            disabled:
                                              _vm.disableCompanyInfoForm,
                                            width: _vm.$vuetify.breakpoint
                                              .mdAndUp
                                              ? "175"
                                              : "50%",
                                            height: "50",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              disabled:
                                                _vm.disableCompanyInfoForm,
                                              label: "Credit Card",
                                              value: "creditCard",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "mt-3",
                              attrs: { "no-gutters": "", justify: "center" },
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "subtitle-2 font-weight-regular grey--text text--darken-4",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("paymentMethodOption")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "mt-6",
                              attrs: { "no-gutters": "", justify: "center" },
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "subtitle-1 font-weight-medium grey--text text--darken-4",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("billingInformation")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "mb-4",
                              attrs: { "no-gutters": "", justify: "center" },
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-checkbox", {
                                    attrs: { disabled: _vm.disableCheckBox },
                                    on: { change: _vm.setBillingAddress },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "subtitle-2 font-weight-regular grey--text text--darken-4",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "sameBillingAndCompanyAddress"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      660204115
                                    ),
                                    model: {
                                      value: _vm.isBillAddressSame,
                                      callback: function ($$v) {
                                        _vm.isBillAddressSame = $$v
                                      },
                                      expression: "isBillAddressSame",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.isBillAddressSame
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass:
                                            "billing-address-card rounded-lg mb-4",
                                          attrs: {
                                            elevation: "0",
                                            color: "#F5F5F5",
                                            outlined: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-card-subtitle",
                                            {
                                              staticClass:
                                                "body-2 font-weight-regular grey--text text--darken-4",
                                            },
                                            [
                                              _c("div", [
                                                _vm._v(_vm._s(_vm.companyName)),
                                              ]),
                                              _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.companyAddress) +
                                                    ", " +
                                                    _vm._s(_vm.companyCity) +
                                                    ", " +
                                                    _vm._s(_vm.companyState) +
                                                    " " +
                                                    _vm._s(_vm.companyZip) +
                                                    " "
                                                ),
                                              ]),
                                              _c("div", [
                                                _vm._v(
                                                  "Phone: " +
                                                    _vm._s(_vm.companyPhone)
                                                ),
                                              ]),
                                              _c("div", [
                                                _vm._v(
                                                  "Email: " +
                                                    _vm._s(_vm.companyEmail)
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { justify: "center" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "py-0",
                                              attrs: {
                                                cols: "12",
                                                lg: "12",
                                                md: "12",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  "error-messages":
                                                    _vm.billingNameErrors,
                                                  label:
                                                    _vm.$t("billingContact"),
                                                  disabled:
                                                    _vm.disableCompanyInfoForm,
                                                  outlined: "",
                                                  dense: "",
                                                  required: "",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.$v.billingName.$touch()
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.$v.billingName.$touch()
                                                  },
                                                },
                                                model: {
                                                  value: _vm.billingName,
                                                  callback: function ($$v) {
                                                    _vm.billingName = $$v
                                                  },
                                                  expression: "billingName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { justify: "center" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "py-0",
                                              attrs: {
                                                cols: "12",
                                                lg: "6",
                                                md: "6",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  "error-messages":
                                                    _vm.billingPhoneErrors,
                                                  label: _vm.$t("billingPhone"),
                                                  disabled:
                                                    _vm.disableCompanyInfoForm,
                                                  outlined: "",
                                                  dense: "",
                                                  required: "",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.$v.billingPhone.$touch()
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.$v.billingPhone.$touch()
                                                  },
                                                },
                                                model: {
                                                  value: _vm.billingPhone,
                                                  callback: function ($$v) {
                                                    _vm.billingPhone = $$v
                                                  },
                                                  expression: "billingPhone",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "py-0",
                                              attrs: {
                                                cols: "12",
                                                lg: "6",
                                                md: "6",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  "error-messages":
                                                    _vm.billingEmailErrors,
                                                  label: _vm.$t("billingEmail"),
                                                  disabled:
                                                    _vm.disableCompanyInfoForm,
                                                  outlined: "",
                                                  dense: "",
                                                  required: "",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.$v.billingEmail.$touch()
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.$v.billingEmail.$touch()
                                                  },
                                                },
                                                model: {
                                                  value: _vm.billingEmail,
                                                  callback: function ($$v) {
                                                    _vm.billingEmail = $$v
                                                  },
                                                  expression: "billingEmail",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { justify: "center" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "py-0",
                                              attrs: {
                                                cols: "12",
                                                lg: "6",
                                                md: "6",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  "error-messages":
                                                    _vm.billingAddressErrors,
                                                  label: _vm.$t("address"),
                                                  disabled:
                                                    _vm.disableCompanyInfoForm,
                                                  outlined: "",
                                                  dense: "",
                                                  required: "",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.$v.billingAddress.$touch()
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.$v.billingAddress.$touch()
                                                  },
                                                },
                                                model: {
                                                  value: _vm.billingAddress,
                                                  callback: function ($$v) {
                                                    _vm.billingAddress = $$v
                                                  },
                                                  expression: "billingAddress",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "py-0",
                                              attrs: {
                                                cols: "12",
                                                lg: "6",
                                                md: "6",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  "error-messages":
                                                    _vm.billingCityErrors,
                                                  label: _vm.$t("cityText"),
                                                  disabled:
                                                    _vm.disableCompanyInfoForm,
                                                  outlined: "",
                                                  dense: "",
                                                  required: "",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.$v.billingCity.$touch()
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.$v.billingCity.$touch()
                                                  },
                                                },
                                                model: {
                                                  value: _vm.billingCity,
                                                  callback: function ($$v) {
                                                    _vm.billingCity = $$v
                                                  },
                                                  expression: "billingCity",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { justify: "center" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "py-0",
                                              attrs: {
                                                cols: "12",
                                                lg: "6",
                                                md: "6",
                                              },
                                            },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  items: _vm.selectState,
                                                  "error-messages":
                                                    _vm.billingStateErrors,
                                                  "menu-props": {
                                                    bottom: true,
                                                    offsetY: true,
                                                  },
                                                  label: _vm.$t("stateText"),
                                                  disabled:
                                                    _vm.disableCompanyInfoForm,
                                                  outlined: "",
                                                  dense: "",
                                                  required: "",
                                                  "item-value": "text",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.$v.billingState.$touch()
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.$v.billingState.$touch()
                                                  },
                                                },
                                                model: {
                                                  value: _vm.billingState,
                                                  callback: function ($$v) {
                                                    _vm.billingState = $$v
                                                  },
                                                  expression: "billingState",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "py-0",
                                              attrs: {
                                                cols: "12",
                                                lg: "6",
                                                md: "6",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  "error-messages":
                                                    _vm.billingZipErrors,
                                                  label: _vm.$t("zipText"),
                                                  disabled:
                                                    _vm.disableCompanyInfoForm,
                                                  outlined: "",
                                                  dense: "",
                                                  required: "",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.$v.billingZip.$touch()
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.$v.billingZip.$touch()
                                                  },
                                                },
                                                model: {
                                                  value: _vm.billingZip,
                                                  callback: function ($$v) {
                                                    _vm.billingZip = $$v
                                                  },
                                                  expression: "billingZip",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.$vuetify.breakpoint.mdAndUp
                ? _c("v-divider", { attrs: { vertical: "" } })
                : _vm._e(),
              _c(
                "v-col",
                [
                  !_vm.$vuetify.breakpoint.mdAndUp
                    ? _c("v-divider", { staticClass: "my-4 mx-5" })
                    : _vm._e(),
                  _vm.selectedUsers.users !== "10+" &&
                  _vm.selectedUsers.name !== "Free"
                    ? _c(
                        "v-row",
                        {
                          staticClass: "pl-4 checkbox-bill",
                          attrs: { justify: "center" },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "ml-0 mt-5 mr-4",
                              class: !_vm.planDuration
                                ? "grey--text text--darken-4"
                                : "grey--text text--darken-1",
                            },
                            [_vm._v(_vm._s(_vm.$t("billQuarterly")))]
                          ),
                          _c("v-switch", {
                            staticClass:
                              "switch-track-inset-opacity switch-track-inset-height",
                            class: _vm.planDuration
                              ? "grey--text text--darken-4"
                              : "grey--text text--darken-1",
                            attrs: {
                              inset: "",
                              color: "info",
                              label: _vm.$t("billAnnually"),
                              "background-color": "white",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "mr-4",
                                          class: _vm.planDuration
                                            ? "grey--text text--darken-4"
                                            : "grey--text text--darken-1",
                                        },
                                        [_vm._v(_vm._s(_vm.$t("billAnnually")))]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              53876590
                            ),
                            model: {
                              value: _vm.planDuration,
                              callback: function ($$v) {
                                _vm.planDuration = $$v
                              },
                              expression: "planDuration",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-row", { staticClass: "mt-2 px-9" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-subtitle-1 font-weight-medium grey--text text--darken-4",
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("PlanDetails")) + " ")]
                    ),
                  ]),
                  _c(
                    "v-row",
                    { staticClass: "mt-2 px-6" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "caption grey--text text--darken-1 my-auto",
                        },
                        [_vm._v(_vm._s(_vm.$t("plan")))]
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4", md: "6", lg: "4" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.subscriptionsName,
                              label: "",
                              "menu-props": { bottom: true, offsetY: true },
                              dense: "",
                              "hide-details": "",
                              outlined: "",
                              "return-object": "",
                            },
                            model: {
                              value: _vm.selectedPlan,
                              callback: function ($$v) {
                                _vm.selectedPlan = $$v
                              },
                              expression: "selectedPlan",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mt-2 px-6" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "caption grey--text text--darken-1 my-auto",
                        },
                        [_vm._v(_vm._s(_vm.$t("userMaximum")))]
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4", md: "6", lg: "4" } },
                        [
                          _vm.subscriptionsUsers.length > 1
                            ? _c("v-select", {
                                attrs: {
                                  items: _vm.subscriptionsUsers,
                                  "item-text": "users",
                                  "item-value": "item",
                                  label: "",
                                  "menu-props": { bottom: true, offsetY: true },
                                  dense: "",
                                  "hide-details": "",
                                  outlined: "",
                                  "return-object": "",
                                },
                                model: {
                                  value: _vm.selectedUsers,
                                  callback: function ($$v) {
                                    _vm.selectedUsers = $$v
                                  },
                                  expression: "selectedUsers",
                                },
                              })
                            : _vm._e(),
                          _vm.subscriptionsUsers.length === 1
                            ? _c("v-text-field", {
                                attrs: {
                                  disabled: "",
                                  outlined: "",
                                  required: "",
                                  "hide-details": "",
                                  dense: "",
                                },
                                model: {
                                  value: _vm.subscriptionsUsers[0].users,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.subscriptionsUsers[0],
                                      "users",
                                      $$v
                                    )
                                  },
                                  expression: "subscriptionsUsers[0].users",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.selectedUsers.users !== "10+" &&
                  _vm.selectedUsers.name !== "Free"
                    ? _c(
                        "v-row",
                        { staticClass: "my-2 px-6" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "caption grey--text text--darken-1",
                            },
                            [_vm._v(_vm._s(_vm.$t("billing")))]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "text-right grey--text text--darken-4 font-weight-medium body-2",
                              attrs: { cols: "4", md: "6", lg: "4" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    !_vm.planDuration ? "Quarterly" : "Annually"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selectedPlan === "Pro" &&
                  _vm.selectedUsers.users !== "10+"
                    ? _c(
                        "v-row",
                        { staticClass: "mt-2 px-6" },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "rounded-lg",
                                  attrs: {
                                    color: "grey lighten-4",
                                    width: "100%",
                                    elevation: "0",
                                  },
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "grey--text font-weight-bold text-h5 ml-3",
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.selectedPlan) + " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "grey--text font-weight-bold ml-3 text-h5 my-auto",
                                        },
                                        [
                                          _vm._v(
                                            " $" +
                                              _vm._s(_vm.selectedUsers.price) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "caption grey--text text--darken-1 ml-0 pl-6 my-2",
                                          attrs: {
                                            cols: "3",
                                            md: "5",
                                            lg: "3",
                                          },
                                        },
                                        [
                                          _c("v-row", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("perUser")) +
                                                " "
                                            ),
                                          ]),
                                          _c("v-row", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("perMonth")) +
                                                " "
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-divider", { staticClass: "mx-6 mt-8" }),
                  _vm.selectedUsers.users !== "10+"
                    ? _c(
                        "v-row",
                        { staticClass: "mt-1 px-4" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "grey--text text--darken-4 font-weight-bold text-subtitle-1 ml-3 my-auto",
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("totalAmount")) + " ")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "pl-0 grey--text text-right text--darken-4 font-weight-bold text-h5 ml-3 my-auto mr-4",
                              attrs: { cols: "3", lg: "4", md: "4", xl: "4" },
                            },
                            [_vm._v(" $" + _vm._s(_vm.subscriptionPrice) + " ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selectedUsers.users !== "10+"
                    ? _c(
                        "v-row",
                        {
                          class: _vm.$vuetify.breakpoint.mdAndUp
                            ? "ml-5"
                            : "pl-6",
                          attrs: {
                            justify: _vm.$vuetify.breakpoint.mdAndUp
                              ? "center"
                              : "start",
                            "no-gutters": "",
                          },
                        },
                        [
                          _c("v-checkbox", {
                            attrs: { label: _vm.$t("iAgree") },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "mr-2 grey--text text--darken-4 text-subtitle-2 font-weight-regular",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("iAgree")) + " "
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "primary--text mt-n1",
                                              on: {
                                                click: function ($event) {
                                                  _vm.dialog = true
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("termsAndConditions")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3470420486
                            ),
                            model: {
                              value: _vm.checkbox,
                              callback: function ($$v) {
                                _vm.checkbox = $$v
                              },
                              expression: "checkbox",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selectedUsers.users === "10+"
                    ? _c(
                        "v-row",
                        { staticClass: "mt-2 px-6" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "grey--text text--darken-4" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("quoteRequestDescription")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    { staticClass: "mt-2 px-6" },
                    [
                      _c(
                        "v-col",
                        [
                          _vm.selectedUsers.users !== "10+"
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "text-none rounded-lg mx-2",
                                  attrs: {
                                    block: "",
                                    color: "primary",
                                    disabled:
                                      _vm.$v.$invalid ||
                                      !_vm.checkbox ||
                                      _vm.getPaymentType,
                                  },
                                  on: { click: _vm.submit },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("confirm")) + " ")]
                              )
                            : _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "text-none rounded-lg white--text",
                                  attrs: {
                                    block: "",
                                    color: "#F26227",
                                    disabled:
                                      _vm.$v.$invalid || !_vm.paymentType,
                                  },
                                  on: { click: _vm.quoteRequest },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("requestQuote")) + " "
                                  ),
                                ]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("CompanyAgreement", {
        attrs: { dialog: _vm.dialog },
        on: {
          closeDialog: _vm.closeDialog,
          agree: _vm.acceptAgreement,
          declineAgreement: _vm.declineAgreement,
        },
      }),
      _vm.quoteDialog
        ? _c("QuoteDialog", {
            attrs: {
              dialog: _vm.quoteDialog,
              "company-information": _vm.quoteInformation,
            },
            on: { quoteSent: _vm.quoteSent, closeDialog: _vm.closeQuoteDialog },
          })
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { width: "35%" },
          model: {
            value: _vm.isEmailSent,
            callback: function ($$v) {
              _vm.isEmailSent = $$v
            },
            expression: "isEmailSent",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { height: "50%" } },
            [
              _vm.isEmailSent
                ? _c("ConfirmationMessage", {
                    attrs: {
                      icon: _vm.emailSentObject.icon,
                      text: _vm.emailSentObject.text,
                      button: _vm.emailSentObject.button,
                      "show-logo": _vm.emailSentObject.showLogo,
                      "background-color": _vm.emailSentObject.backgroundColor,
                    },
                    on: { onBackToTicket: _vm.onBackToTicket },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }