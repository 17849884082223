<template>
  <v-card elevation="0" class="pb-10">
    <v-card-text class="pa-0 mb-6">
      <v-row
        justify="center"
        class="mx-auto mt-2"
        :class="$vuetify.breakpoint.smAndDown ? 'mb-8' : 'mb-6'"
      >
        <span
          class="text-h5 font-weight-bold grey--text text--darken-4 mx-auto text-center"
          style="display: block"
        >
          <template v-if="$vuetify.breakpoint.mdAndUp">
            Company and Billing Information
          </template>
          <template v-else>
            Company and Billing<br>Information
          </template>
        </span>
      </v-row>
      <v-divider v-if="$vuetify.breakpoint.mdAndUp" class="mx-8"></v-divider>
    </v-card-text>

    <v-card-text
      class="pa-0"
      :class="$vuetify.breakpoint.mdAndUp ? 'px-6' : 'pl-1 pr-0'"
    >
      <v-row no-gutters justify="center">
        <v-col
          cols="12"
          lg="8"
          md="8"
          class="pl-3 pr-6 company-info-form-container"
        >
          <v-row
            no-gutters
            justify="center"
            class="mb-4 subtitle-1 font-weight-medium grey--text text--darken-4"
          >
            <v-col cols="12">
              {{ $t("811CenterAndExcavatorType") }}
            </v-col>
          </v-row>
          <v-row class="mb-2">
            <v-col cols="12" lg="6" md="6" class="py-0">
              <v-select
                v-model="selectedCenter"
                :items="select811CenterOptions"
                :error-messages="centerErrors"
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                required
                dense
                :label="$t('811Center')"
                item-text="center_name"
                item-value="center_id"
                @change="onCenterChange"
                @blur="onCenterChange"
              ></v-select>
            </v-col>
            <v-col cols="12" lg="6" md="6" class="py-0">
              <v-select
                v-model="excavatorType"
                :items="excavatorTypes"
                :disabled="disableExcavatorTypeSelection"
                :error-messages="centerTypeErrors"
                outlined
                dense
                :menu-props="{ bottom: true, offsetY: true }"
                required
                :label="$t('excavatorType')"
                item-value="text"
                @change="$v.excavatorType.$touch()"
                @blur="$v.excavatorType.$touch()"
              ></v-select>
            </v-col>
          </v-row>
          <v-divider />
          <v-row no-gutters class="mt-8 mb-6">
            <v-col
              cols="12"
              class="subtitle-1 font-weight-medium grey--text text--darken-4"
            >
              {{ $t("companyInformation") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="companyName"
                :error-messages="companyNameErrors"
                :label="$t('companyName')"
                outlined
                required
                dense
                :disabled="disableCompanyInfoForm"
                @input="$v.companyName.$touch()"
                @blur="$v.companyName.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="6" md="6" class="py-0">
              <v-text-field
                v-model="companyPhone"
                :error-messages="companyPhoneErrors"
                :label="$t('companyPhone')"
                :disabled="disableCompanyInfoForm"
                outlined
                required
                dense
                @input="$v.companyPhone.$touch()"
                @blur="$v.companyPhone.$touch()"
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="6" md="6" class="py-0">
              <v-text-field
                v-model="companyEmail"
                :error-messages="companyEmailErrors"
                :label="$t('companyEmail')"
                :disabled="disableCompanyInfoForm"
                outlined
                required
                dense
                @input="$v.companyEmail.$touch()"
                @blur="$v.companyEmail.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" lg="6" md="6" class="py-0">
              <v-text-field
                v-model="companyAddress"
                :error-messages="companyAddressErrors"
                :label="$t('companyAddressText')"
                :disabled="disableCompanyInfoForm"
                outlined
                required
                dense
                @input="$v.companyAddress.$touch()"
                @blur="$v.companyAddress.$touch()"
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="6" md="6" class="py-0">
              <v-text-field
                v-model="companyCity"
                :error-messages="companyCityErrors"
                :label="$t('cityText')"
                :disabled="disableCompanyInfoForm"
                outlined
                required
                dense
                @input="$v.companyCity.$touch()"
                @blur="$v.companyCity.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" class="mb-2">
            <v-col cols="12" lg="6" md="6" class="py-0">
              <v-select
                v-model="companyState"
                :items="selectState"
                :error-messages="companyStateErrors"
                :disabled="disableCompanyInfoForm"
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                required
                dense
                :label="$t('stateText')"
                item-value="text"
                @change="$v.companyState.$touch()"
                @blur="$v.companyState.$touch()"
              ></v-select>
            </v-col>

            <v-col cols="12" lg="6" md="6" class="py-0">
              <v-text-field
                v-model="companyZip"
                :error-messages="companyZipErrors"
                :label="$t('zipText')"
                :disabled="disableCompanyInfoForm"
                outlined
                required
                dense
                @input="$v.companyZip.$touch()"
                @blur="$v.companyZip.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider v-if="selectedPlan !== 'Free'" />
          <div v-if="selectedPlan !== 'Free'">
            <v-row no-gutters justify="center" class="mt-6">
              <v-col
                cols="12"
                class="subtitle-1 font-weight-medium grey--text text--darken-4"
              >
                {{ $t("paymentMethod") }}
              </v-col>
            </v-row>
            <v-row class="mt-4 radio-group-row" no-gutters>
              <v-col cols="12">
                <v-radio-group v-model="paymentType" row hide-details>
                  <v-card
                    :class="
                      paymentType === 'invoice'
                        ? 'rounded-lg billing-type-style-primary'
                        : 'rounded-lg billing-type-style'
                    "
                    class="pa-3 payment-type-option"
                    outlined
                    :disabled="disableCompanyInfoForm"
                    :width="$vuetify.breakpoint.mdAndUp ? '175' : '50%'"
                    height="50"
                  >
                    <v-radio
                      :label="$t('invoice')"
                      value="invoice"
                      :disabled="disableCompanyInfoForm"
                    ></v-radio>
                  </v-card>
                  <v-card
                    :class="
                      paymentType === 'creditCard'
                        ? 'rounded-lg billing-type-style-primary'
                        : 'rounded-lg billing-type-style'
                    "
                    class="pa-3 ml-5 payment-type-option"
                    outlined
                    light
                    :disabled="disableCompanyInfoForm"
                    :width="$vuetify.breakpoint.mdAndUp ? '175' : '50%'"
                    height="50"
                  >
                    <v-radio
                      :disabled="disableCompanyInfoForm"
                      label="Credit Card"
                      value="creditCard"
                    ></v-radio>
                  </v-card>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row no-gutters justify="center" class="mt-3">
              <v-col
                cols="12"
                class="subtitle-2 font-weight-regular grey--text text--darken-4"
              >
                {{ $t("paymentMethodOption") }}
              </v-col>
            </v-row>
            <v-row no-gutters justify="center" class="mt-6">
              <v-col
                cols="12"
                class="subtitle-1 font-weight-medium grey--text text--darken-4"
              >
                {{ $t("billingInformation") }}
              </v-col>
            </v-row>
            <v-row no-gutters justify="center" class="mb-4">
              <v-col cols="12">
                <v-checkbox
                  v-model="isBillAddressSame"
                  :disabled="disableCheckBox"
                  @change="setBillingAddress"
                >
                  <template v-slot:label>
                    <div
                      class="subtitle-2 font-weight-regular grey--text text--darken-4"
                    >
                      {{ $t("sameBillingAndCompanyAddress") }}
                    </div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row v-if="isBillAddressSame">
              <v-col cols="12" class="py-0">
                <v-card
                  elevation="0"
                  color="#F5F5F5"
                  outlined
                  class="billing-address-card rounded-lg mb-4"
                >
                  <v-card-subtitle
                    class="body-2 font-weight-regular grey--text text--darken-4"
                  >
                    <div>{{ companyName }}</div>
                    <div>
                      {{ companyAddress }}, {{ companyCity }},
                      {{ companyState }}
                      {{ companyZip }}
                    </div>
                    <div>Phone: {{ companyPhone }}</div>
                    <div>Email: {{ companyEmail }}</div>
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-else no-gutters>
              <v-col cols="12">
                <v-row justify="center">
                  <v-col cols="12" lg="12" md="12" class="py-0">
                    <v-text-field
                      v-model="billingName"
                      :error-messages="billingNameErrors"
                      :label="$t('billingContact')"
                      :disabled="disableCompanyInfoForm"
                      outlined
                      dense
                      required
                      @input="$v.billingName.$touch()"
                      @blur="$v.billingName.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col cols="12" lg="6" md="6" class="py-0">
                    <v-text-field
                      v-model="billingPhone"
                      :error-messages="billingPhoneErrors"
                      :label="$t('billingPhone')"
                      :disabled="disableCompanyInfoForm"
                      outlined
                      dense
                      required
                      @input="$v.billingPhone.$touch()"
                      @blur="$v.billingPhone.$touch()"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" lg="6" md="6" class="py-0">
                    <v-text-field
                      v-model="billingEmail"
                      :error-messages="billingEmailErrors"
                      :label="$t('billingEmail')"
                      :disabled="disableCompanyInfoForm"
                      outlined
                      dense
                      required
                      @input="$v.billingEmail.$touch()"
                      @blur="$v.billingEmail.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col cols="12" lg="6" md="6" class="py-0">
                    <v-text-field
                      v-model="billingAddress"
                      :error-messages="billingAddressErrors"
                      :label="$t('address')"
                      :disabled="disableCompanyInfoForm"
                      outlined
                      dense
                      required
                      @input="$v.billingAddress.$touch()"
                      @blur="$v.billingAddress.$touch()"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" lg="6" md="6" class="py-0">
                    <v-text-field
                      v-model="billingCity"
                      :error-messages="billingCityErrors"
                      :label="$t('cityText')"
                      :disabled="disableCompanyInfoForm"
                      outlined
                      dense
                      required
                      @input="$v.billingCity.$touch()"
                      @blur="$v.billingCity.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col cols="12" lg="6" md="6" class="py-0">
                    <v-select
                      v-model="billingState"
                      :items="selectState"
                      :error-messages="billingStateErrors"
                      :menu-props="{ bottom: true, offsetY: true }"
                      :label="$t('stateText')"
                      :disabled="disableCompanyInfoForm"
                      outlined
                      dense
                      required
                      item-value="text"
                      @change="$v.billingState.$touch()"
                      @blur="$v.billingState.$touch()"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" lg="6" md="6" class="py-0">
                    <v-text-field
                      v-model="billingZip"
                      :error-messages="billingZipErrors"
                      :label="$t('zipText')"
                      :disabled="disableCompanyInfoForm"
                      outlined
                      dense
                      required
                      @input="$v.billingZip.$touch()"
                      @blur="$v.billingZip.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-divider v-if="$vuetify.breakpoint.mdAndUp" vertical></v-divider>
        <v-col>
          <v-divider
            v-if="!$vuetify.breakpoint.mdAndUp"
            class="my-4 mx-5"
          ></v-divider>
          <v-row
            v-if="
              selectedUsers.users !== '10+' && selectedUsers.name !== 'Free'
            "
            justify="center"
            class="pl-4 checkbox-bill"
          >
            <span
              class="ml-0 mt-5 mr-4"
              :class="
                !planDuration
                  ? 'grey--text text--darken-4'
                  : 'grey--text text--darken-1'
              "
              >{{ $t("billQuarterly") }}</span
            >
            <v-switch
              v-model="planDuration"
              inset
              color="info"
              :label="$t('billAnnually')"
              class="switch-track-inset-opacity switch-track-inset-height"
              :class="
                planDuration
                  ? 'grey--text text--darken-4'
                  : 'grey--text text--darken-1'
              "
              background-color="white"
              ><template v-slot:label>
                <span
                  class="mr-4"
                  :class="
                    planDuration
                      ? 'grey--text text--darken-4'
                      : 'grey--text text--darken-1'
                  "
                  >{{ $t("billAnnually") }}</span
                >
              </template></v-switch
            >
          </v-row>
          <v-row class="mt-2 px-9">
            <div
              class="text-subtitle-1 font-weight-medium grey--text text--darken-4"
            >
              {{ $t("PlanDetails") }}
            </div>
          </v-row>
          <v-row class="mt-2 px-6">
            <v-col class="caption grey--text text--darken-1 my-auto">{{
              $t("plan")
            }}</v-col>
            <v-col cols="4" md="6" lg="4">
              <v-select
                v-model="selectedPlan"
                :items="subscriptionsName"
                label=""
                :menu-props="{ bottom: true, offsetY: true }"
                dense
                hide-details
                outlined
                return-object
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="mt-2 px-6">
            <v-col class="caption grey--text text--darken-1 my-auto">{{
              $t("userMaximum")
            }}</v-col>
            <v-col cols="4" md="6" lg="4">
              <v-select
                v-if="subscriptionsUsers.length > 1"
                v-model="selectedUsers"
                :items="subscriptionsUsers"
                item-text="users"
                item-value="item"
                label=""
                :menu-props="{ bottom: true, offsetY: true }"
                dense
                hide-details
                outlined
                return-object
              ></v-select>
              <v-text-field
                v-if="subscriptionsUsers.length === 1"
                v-model="subscriptionsUsers[0].users"
                disabled
                outlined
                required
                hide-details
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row
            v-if="
              selectedUsers.users !== '10+' && selectedUsers.name !== 'Free'
            "
            class="my-2 px-6"
          >
            <v-col class="caption grey--text text--darken-1">{{
              $t("billing")
            }}</v-col>
            <v-col
              cols="4"
              md="6"
              lg="4"
              class="text-right grey--text text--darken-4 font-weight-medium body-2"
            >
              {{ !planDuration ? "Quarterly" : "Annually" }}
            </v-col>
          </v-row>
          <v-row
            v-if="selectedPlan === 'Pro' && selectedUsers.users !== '10+'"
            class="mt-2 px-6"
          >
            <v-col>
              <v-card
                color="grey lighten-4"
                width="100%"
                elevation="0"
                class="rounded-lg"
              >
                <v-row>
                  <v-col class="grey--text font-weight-bold text-h5 ml-3">
                    {{ selectedPlan }}
                  </v-col>
                  <span
                    class="grey--text font-weight-bold ml-3 text-h5 my-auto"
                  >
                    ${{ selectedUsers.price }}
                  </span>
                  <v-col
                    cols="3"
                    md="5"
                    lg="3"
                    class="caption grey--text text--darken-1 ml-0 pl-6 my-2"
                  >
                    <v-row> {{ $t("perUser") }} </v-row>
                    <v-row> {{ $t("perMonth") }} </v-row>
                  </v-col>
                </v-row></v-card
              >
            </v-col>
          </v-row>
          <v-divider class="mx-6 mt-8"></v-divider>
          <v-row v-if="selectedUsers.users !== '10+'" class="mt-1 px-4">
            <v-col
              class="grey--text text--darken-4 font-weight-bold text-subtitle-1 ml-3 my-auto"
            >
              {{ $t("totalAmount") }}
            </v-col>
            <v-col
              cols="3"
              lg="4"
              md="4"
              xl="4"
              class="pl-0 grey--text text-right text--darken-4 font-weight-bold text-h5 ml-3 my-auto mr-4"
            >
              ${{ subscriptionPrice }}
            </v-col>
          </v-row>
          <v-row
            v-if="selectedUsers.users !== '10+'"
            :justify="$vuetify.breakpoint.mdAndUp ? 'center' : 'start'"
            :class="$vuetify.breakpoint.mdAndUp ? 'ml-5' : 'pl-6'"
            no-gutters
          >
            <v-checkbox v-model="checkbox" :label="$t('iAgree')"
              ><template v-slot:label>
                <span
                  class="mr-2 grey--text text--darken-4 text-subtitle-2 font-weight-regular"
                  >{{ $t("iAgree") }}
                  <a class="primary--text mt-n1" @click="dialog = true">{{
                    $t("termsAndConditions")
                  }}</a></span
                >
              </template></v-checkbox
            >
          </v-row>
          <v-row v-if="selectedUsers.users === '10+'" class="mt-2 px-6"
            ><v-col class="grey--text text--darken-4">
              {{ $t("quoteRequestDescription") }}
            </v-col></v-row
          >
          <v-row class="mt-2 px-6">
            <v-col>
              <v-btn
                v-if="selectedUsers.users !== '10+'"
                block
                class="text-none rounded-lg mx-2"
                color="primary"
                :disabled="$v.$invalid || !checkbox || getPaymentType"
                @click="submit"
              >
                {{ $t("confirm") }}
              </v-btn>
              <v-btn
                v-else
                block
                class="text-none rounded-lg white--text"
                color="#F26227"
                :disabled="$v.$invalid || !paymentType"
                @click="quoteRequest"
              >
                {{ $t("requestQuote") }}
              </v-btn></v-col
            >
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <CompanyAgreement
      :dialog="dialog"
      @closeDialog="closeDialog"
      @agree="acceptAgreement"
      @declineAgreement="declineAgreement"
    />
    <QuoteDialog
      v-if="quoteDialog"
      :dialog="quoteDialog"
      :company-information="quoteInformation"
      @quoteSent="quoteSent"
      @closeDialog="closeQuoteDialog"
    />
    <v-dialog v-model="isEmailSent" width="35%"
      ><v-card height="50%">
        <ConfirmationMessage
          v-if="isEmailSent"
          :icon="emailSentObject.icon"
          :text="emailSentObject.text"
          :button="emailSentObject.button"
          :show-logo="emailSentObject.showLogo"
          :background-color="emailSentObject.backgroundColor"
          @onBackToTicket="onBackToTicket" /></v-card
    ></v-dialog>
  </v-card>
</template>

<script>
import { validationMixin } from "vuelidate";
import { helpers } from "vuelidate/lib/validators";
import {
  required,
  maxLength,
  minLength,
  email,
} from "vuelidate/lib/validators";
import states from "@/static/states.json";
const zip = helpers.regex("serial", /^\d{5}(-\d{4})?$/);
const phone = helpers.regex(
  "serial",
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
);
import { setAuthDetails } from "@/store/utils/utils";
import UserData from "../../../mixins/UserData.vue";

export default {
  name: "CompanyInformation",
  components: {
    CompanyAgreement: () => import("./CompanyAgreement.vue"),
    QuoteDialog: () => import("./QuoteDetailsDialog.vue"),
    ConfirmationMessage: () =>
      import("../../molecules/ConfirmationMessage.vue"),
  },
  mixins: [validationMixin, UserData],
  props: {
    subscriptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selectedSubscription: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  validations() {
    if (this.selectedPlan === "Pro") {
      return {
        companyName: {
          required,
          maxLength: maxLength(80),
          minLength: minLength(1),
        },
        companyAddress: {
          required,
          maxLength: maxLength(80),
          minLength: minLength(1),
        },
        companyCity: {
          required,
          maxLength: maxLength(80),
          minLength: minLength(1),
        },
        companyState: { required },
        companyZip: { required, zip },
        companyPhone: { required, phone },
        companyEmail: { required, email },
        billingName: {
          required,
          maxLength: maxLength(80),
          minLength: minLength(1),
        },
        billingAddress: {
          required,
          maxLength: maxLength(80),
          minLength: minLength(1),
        },
        billingCity: {
          required,
          maxLength: maxLength(80),
          minLength: minLength(1),
        },
        billingState: { required },
        billingZip: { required, zip },
        billingPhone: { required, phone },
        billingEmail: { required, email },
        selectedCenter: { required },
        excavatorType: { required },
      };
    } else {
      return {
        companyName: {
          required,
          maxLength: maxLength(80),
          minLength: minLength(1),
        },
        companyAddress: {
          required,
          maxLength: maxLength(80),
          minLength: minLength(1),
        },
        companyCity: {
          required,
          maxLength: maxLength(80),
          minLength: minLength(1),
        },
        companyState: { required },
        companyZip: { required, zip },
        companyPhone: { required, phone },
        companyEmail: { required, email },
        selectedCenter: { required },
        excavatorType: { required },
      };
    }
  },
  data() {
    return {
      step: 1,
      companyName: "",
      companyAddress: "",
      companyPhone: "",
      companyEmail: "",
      companyCity: "",
      companyState: "",
      companyZip: "",
      billingName: "",
      billingAddress: "",
      billingPhone: "",
      billingEmail: "",
      billingCity: "",
      billingState: "",
      billingZip: "",
      isCompanyNameExists: false,
      selectState: states.state,
      paymentType: null,
      selectedCenter: null,
      excavatorType: null,
      isBillAddressSame: false,
      planDuration: true,
      selectedPlan: "Free",
      selectedUsers: "",
      dialog: false,
      quoteDialog: false,
      checkbox: false,
      quoteInformation: {},
      isEmailSent: false,
      authDetails: {},
      emailSentObject: {
        text: {
          bodyLabel: "Your request has been sent successfully to <strong>sales@norfield.com</strong>. Our team will get back to you very soon.",
          headerLabel: "Email Sent!",
          bodyLabelParams: {
            email: "",
          },
        },
        button: {
          buttonLabel: "done",
          buttonColor: "#0073D1",
          buttonOutlined: false,
          buttonEvent: "onBackToTicket",
        },
        icon: {
          iconName: "",
          iconColor: "",
          isIcon: false,
        },
        showLogo: false,
        backgroundColor: false,
      },
    };
  },
  computed: {
    getPaymentType() {
      if (this.selectedPlan === "Pro") {
        return !this.paymentType;
      } else {
        return false;
      }
    },
    subscriptionsName() {
      const subscriptionArray = [];
      this.subscriptions.forEach((item) => {
        if (!subscriptionArray.includes(item.name))
          subscriptionArray.push(item.name);
      });
      return subscriptionArray;
    },
    subscriptionsUsers() {
      const subscriptionArray = [];
      this.subscriptions.forEach((item) => {
        if (
          !subscriptionArray.includes(item.users) &&
          this.selectedPlan === item.name
        )
          subscriptionArray.push(item);
      });
      return subscriptionArray;
    },
    subscriptionPrice() {
      return this.planDuration
        ? this.selectedUsers.price * 12 * Number(this.selectedUsers.users)
        : this.selectedUsers.price * 3 * Number(this.selectedUsers.users);
    },
    centerErrors() {
      const errors = [];
      if (!this.$v.selectedCenter.$dirty) return errors;
      !this.$v.selectedCenter.required &&
        errors.push(this.$t("centerRequiredError"));
      return errors;
    },
    centerTypeErrors() {
      const errors = [];
      if (!this.$v.excavatorType.$dirty) return errors;
      !this.$v.excavatorType.required &&
        errors.push(this.$t("excavatorTypeRequiredError"));
      return errors;
    },
    companyNameErrors() {
      const errors = [];
      if (!this.$v.companyName.$dirty) return errors;
      !this.$v.companyName.maxLength &&
        errors.push(this.$t("companyNameLengthError"));
      !this.$v.companyName.required &&
        errors.push(this.$t("companyNameRequiredError"));
      return errors;
    },
    companyAddressErrors() {
      const errors = [];
      if (!this.$v.companyAddress.$dirty) return errors;
      !this.$v.companyAddress.maxLength &&
        errors.push(this.$t("companyAddressLengthError"));
      !this.$v.companyAddress.required &&
        errors.push(this.$t("companyAddressRequiredError"));
      return errors;
    },
    companyPhoneErrors() {
      const errors = [];
      if (!this.$v.companyPhone.$dirty) return errors;
      !this.$v.companyPhone.phone &&
        errors.push(this.$t("companyPhoneValidError"));
      !this.$v.companyPhone.required &&
        errors.push(this.$t("phoneRequiredError"));
      return errors;
    },
    companyEmailErrors() {
      const errors = [];
      if (!this.$v.companyEmail.$dirty) return errors;
      !this.$v.companyEmail.email &&
        errors.push(this.$t("companyEmailValidError"));
      !this.$v.companyEmail.required &&
        errors.push(this.$t("emailRequiredError"));
      return errors;
    },
    companyCityErrors() {
      const errors = [];
      if (!this.$v.companyCity.$dirty) return errors;
      !this.$v.companyCity.maxLength && errors.push(this.$t("cityLengthError"));
      !this.$v.companyCity.required &&
        errors.push(this.$t("cityRequiredError"));
      return errors;
    },
    companyStateErrors() {
      const errors = [];
      if (!this.$v.companyState.$dirty) return errors;
      !this.$v.companyState.required &&
        errors.push(this.$t("stateRequiredError"));
      return errors;
    },
    companyZipErrors() {
      const errors = [];
      if (!this.$v.companyZip.$dirty) return errors;
      !this.$v.companyZip.zip && errors.push(this.$t("zipValidError"));
      !this.$v.companyZip.required && errors.push(this.$t("zipRequiredError"));
      return errors;
    },
    billingNameErrors() {
      const errors = [];
      if (!this.$v.billingName.$dirty) return errors;
      !this.$v.billingName.maxLength &&
        errors.push(this.$t("billingNameLengthError"));
      !this.$v.billingName.required &&
        errors.push(this.$t("billingNameRequiredError"));
      return errors;
    },
    billingAddressErrors() {
      const errors = [];
      if (!this.$v.billingAddress.$dirty) return errors;
      !this.$v.billingAddress.maxLength &&
        errors.push(this.$t("billingAddressLengthError"));
      !this.$v.billingAddress.required &&
        errors.push(this.$t("billingAddressRequiredError"));
      return errors;
    },
    billingPhoneErrors() {
      const errors = [];
      if (!this.$v.billingPhone.$dirty) return errors;
      !this.$v.billingPhone.phone &&
        errors.push(this.$t("billingPhoneValidError"));
      !this.$v.billingPhone.required &&
        errors.push(this.$t("phoneRequiredError"));
      return errors;
    },
    billingEmailErrors() {
      const errors = [];
      if (!this.$v.billingEmail.$dirty) return errors;
      !this.$v.billingEmail.email &&
        errors.push(this.$t("billingEmailValidError"));
      !this.$v.billingEmail.required &&
        errors.push(this.$t("emailRequiredError"));
      return errors;
    },
    billingCityErrors() {
      const errors = [];
      if (!this.$v.billingCity.$dirty) return errors;
      !this.$v.billingCity.maxLength && errors.push(this.$t("cityLengthError"));
      !this.$v.billingCity.required &&
        errors.push(this.$t("cityRequiredError"));
      return errors;
    },
    billingStateErrors() {
      const errors = [];
      if (!this.$v.billingState.$dirty) return errors;
      !this.$v.billingState.required &&
        errors.push(this.$t("stateRequiredError"));
      return errors;
    },
    billingZipErrors() {
      const errors = [];
      if (!this.$v.billingZip.$dirty) return errors;
      !this.$v.billingZip.zip && errors.push(this.$t("zipValidError"));
      !this.$v.billingZip.required && errors.push(this.$t("zipRequiredError"));
      return errors;
    },
    centerData() {
      return this.$store.state.User.centers;
    },
    select811CenterOptions() {
      return this.centerData.map(({ center_id, center_name }, index) => {
        return { center_id, center_name };
      });
    },
    excavatorTypes() {
      if (this.selectedCenter) {
        let tempCallerTypes = this.centerData.filter((element, index) => {
          return element["center_id"] === this.selectedCenter;
        });
        return tempCallerTypes[0]["caller_types"];
      } else {
        return [];
      }
    },
    disableExcavatorTypeSelection() {
      return this.excavatorTypes.length === 0;
    },
    disableCompanyInfoForm() {
      return !this.selectedCenter || !this.excavatorType;
    },
    disableCheckBox() {
      return !(this.companyAddress && this.companyPhone && this.companyEmail);
    },
  },
  watch: {
    subscriptionsUsers() {
      this.selectedUsers =
        this.selectedUsers.name === this.subscriptionsUsers[0].name
          ? this.selectedUsers
          : this.subscriptionsUsers[0];
    },
  },
  created() {
    this.selectedUsers = this.selectedSubscription;
    this.selectedPlan = this.selectedSubscription.name;
    this.onBackSetCompanyInformation();
  },
  methods: {
    acceptAgreement() {
      this.checkbox = true;
      this.dialog = false;
    },
    closeDialog() {
      this.dialog = false;
    },
    declineAgreement() {
      this.checkbox = false;
      this.dialog = false;
    },
    closeQuoteDialog() {
      this.quoteDialog = false;
    },
    async quoteSent(companyData) {
      this.$store.commit("setCompanyData", companyData);
      const CompanyStatus = await this.$store.dispatch(
        "createCompany",
        this.$store.state.Company.company,
      );
      if (CompanyStatus.status !== "error") {
        // This is temporary, this has to be removed after the refresh token is implemented
        this.quoteDialog = false;
        this.isEmailSent = true;
        this.fetchNewtinData();
        this.authDetails = {
          userId: this.$store.state.User.user.user_id,
          token: CompanyStatus[0].token,
        };
      }
    },
    async onBackToTicket() {
      await setAuthDetails(this.authDetails);
      this.$router.push("/companyInfo").catch();
    },
    onBackSetCompanyInformation() {
      this.companyName = this.$store.state.Company.company.name;
      this.companyAddress = this.$store.state.Company.company.address;
      this.companyPhone = this.$store.state.Company.company.phone;
      this.companyEmail = this.$store.state.Company.company.email;
      this.companyCity = this.$store.state.Company.company.city;
      this.companyState = this.$store.state.Company.company.state;
      this.companyZip = this.$store.state.Company.company.zip;
    },
    changeStep() {
      this.step = this.step == 1 ? 2 : 1;
    },
    quoteRequest() {
      this.quoteDialog = false;
      let tempCentersArray = [];
      tempCentersArray.push({
        default: true,
        center_id: this.selectedCenter,
        excavator_type: this.excavatorType,
      });
      this.quoteInformation = {
        name: this.companyName,
        type: this.excavatorType,
        address: this.companyAddress,
        phone: this.companyPhone,
        email: this.companyEmail,
        city: this.companyCity,
        state: this.companyState,
        zip: this.companyZip,
        billing_contact: this.billingName,
        billing_address: this.billingAddress,
        billing_phone: this.billingPhone,
        billing_email: this.billingEmail,
        billing_city: this.billingCity,
        billing_state: this.billingState,
        billing_zip: this.billingZip,
        payment_method: this.paymentType,
        centers: JSON.stringify(tempCentersArray),
        users: this.selectedUsers.users,
        subscription_id: this.selectedUsers.subscription_id,
        price: this.subscriptionPrice,
        billing_cycle: !this.planDuration ? "Quarterly" : "Annually",
        eula: this.checkbox,
      };
      this.quoteDialog = true;
    },
    async submit() {
      let tempCentersArray = [];
      let companyDetails = {};
      tempCentersArray.push({
        default: true,
        center_id: this.selectedCenter,
        excavator_type: this.excavatorType,
      });
      if (this.selectedPlan === "Pro") {
        companyDetails = {
          name: this.companyName,
          type: this.excavatorType,
          address: this.companyAddress,
          phone: this.companyPhone,
          email: this.companyEmail,
          city: this.companyCity,
          state: this.companyState,
          zip: this.companyZip,
          billing_contact: this.billingName,
          billing_address: this.billingAddress,
          billing_phone: this.billingPhone,
          billing_email: this.billingEmail,
          billing_city: this.billingCity,
          billing_state: this.billingState,
          billing_zip: this.billingZip,
          payment_method: this.paymentType,
          centers: JSON.stringify(tempCentersArray),
          users: this.selectedUsers.users,
          subscription_id: this.selectedUsers.subscription_id,
          price: this.subscriptionPrice,
          billing_cycle: !this.planDuration ? "Quarterly" : "Annually",
          eula: this.checkbox,
        };
      } else {
        companyDetails = {
          name: this.companyName,
          type: this.excavatorType,
          address: this.companyAddress,
          phone: this.companyPhone,
          email: this.companyEmail,
          city: this.companyCity,
          state: this.companyState,
          zip: this.companyZip,
          centers: JSON.stringify(tempCentersArray),
          users: this.selectedUsers.users,
          subscription_id: this.selectedUsers.subscription_id,
          price: this.selectedUsers.price,
          eula: this.checkbox,
        };
      }
      this.$emit("submitCompanyDetails", companyDetails);
    },
    onCenterChange() {
      this.$v.selectedCenter.$touch();
      this.excavatorType = null;
    },
    setBillingAddress() {
      if (this.isBillAddressSame) {
        this.billingName = this.companyName;
        this.billingAddress = this.companyAddress;
        this.billingCity = this.companyCity;
        this.billingState = this.companyState;
        this.billingPhone = this.companyPhone;
        this.billingZip = this.companyZip;
        this.billingEmail = this.companyEmail;
      } else {
        this.billingName = "";
        this.billingAddress = "";
        this.billingCity = "";
        this.billingState = "";
        this.billingPhone = "";
        this.billingZip = "";
        this.billingEmail = "";
      }
    },
  },
};
</script>

<style lang="scss">
.company-info-form-container {
  .billing-type-style {
    border: 1px solid #bdbdbd !important;
  }
  .billing-type-style-primary {
    border: 1px solid #0073d1 !important;
  }
  .billing-address-card {
    border: 1px solid #e0e0e0 !important;
  }
  .v-input--radio-group--row .v-input--radio-group__input {
    flex-wrap: nowrap !important;
  }
  .radio-group-row {
    display: block;
  }
}
.checkbox-bill {
  .v-input--selection-controls__ripple {
    color: #2196f3 !important;
    caret-color: #2196f3 !important;
  }
  .theme--light.v-input--switch .v-input--switch__track {
    color: #2196f3 !important;
    caret-color: #2196f3 !important;
  }
  .theme--light.v-input--switch .v-input--switch__thumb {
    color: #2196f3 !important;
  }
}
</style>
